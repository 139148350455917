@mixin dropAreaProps {
	content: '\0a00';
	font-size: 0.5em;
	opacity: 0;
	text-transform: uppercase;
	line-height: 1.8;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin absoluteCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin button {
	border: none;
	border-radius: var(--borderRadius);
	outline: none;
	padding: 8px 12px;
	font-weight: normal;
	line-height: 1.8;
	color: var(--color-text-dark);
	cursor: pointer;
	text-decoration: none;
	transition: all 250ms ease;

	&:hover,
	&:active {
		background: var(--color-bg);
	}
}
