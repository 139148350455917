button,
input[type='submit'],
input[type='button'],
.button {
	@include button;

	.wp-core-ui & {
		@include button;
	}
}

button {
	&.icon {
		color: var(--color-text);
	}

	&.control {
		background-color: var(--color-main-transparent);
		border-radius: var(--borderRadius);
		font-size: 1.3em;
		font-weight: normal;
		transition: all 100ms ease-out;
		line-height: initial;
		z-index: 10;

		&:hover {
			&:not([disabled]) {
				color: var(--color-text-dark);
				background-color: var(--color-main-transparent);
			}
		}

		&.active {
			background-color: var(--color-text);
			color: var(--color-neutral);
			pointer-events: none;
		}
	}

	&:not([disabled]) {
		cursor: pointer;
	}

	&:not(.picker__swatch):not(.react-datepicker__navigation):not(.postLink):not(.control):not(.viewRange__input) {
		border: none;
	}
}

.visible {
	visibility: visible;
}

.hidden {
	visibility: hidden;
	position: absolute;
}

.row {
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.row-middle {
	align-items: center;
}

.col {
	flex-grow: 1;
	max-width: 100%;

	&__start,
	&__end {
		flex: 0 0 50px;
	}

	&__start {
		justify-content: flex-start;
		text-align: left;
	}

	&__center {
		padding: 4px;
		border-radius: var(--borderRadius);
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		text-align: center;
		flex-wrap: nowrap;

		& > div {
			flex: 1 1 auto;
		}
	}

	&__end {
		justify-content: flex-end;
		text-align: right;
	}
}

.icon {
	color: var(--color-text);
	font-family: 'Material Icons', serif;
	font-style: normal;
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
	text-align: center;
}

.fieldGroup {
	margin: 12px 0;

	&:first-of-type {
		margin-top: 0;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	&.inlineLabel {
		label,
		input,
		select {
			display: inline-block;
		}

		label {
			margin-right: 8px;
		}
	}

	label {
		display: block;
	}

	input,
	textarea,
	select,
	fieldset {
		display: block;
		border: 1px solid var(--color-text-light);
	}

	input,
	textarea,
	select {
		border-radius: var(--borderRadius-half);
	}

	input,
	textarea {
		padding: 6px;
	}

	input {
		&.mini {
			padding-top: 0;
			padding-bottom: 0;
			width: 40px;
		}
	}

	select {
		cursor: pointer;
		padding: 6px 26px 6px 6px;
	}
}

.widget {
	border: 1px solid var(--color-border);
	position: relative;
	padding: 12px;
	width: 100%;
	margin: 0 0 12px;

	&:last-of-type {
		margin-bottom: 0;
	}

	.widgetTitle {
		margin: 0 0 1em;
		font-size: 1.2em;
		color: var(--color-text-dark);
	}

	.help {
		position: absolute;
		right: 1em;
		bottom: 1em;
	}
}

.help {
	display: inline-block;
	line-height: 1;

	&:hover {
		.tooltip {
			width: auto;
			height: auto;
			opacity: 1;
		}
	}

	.tooltip-icon {
		font-size: 0.9em;
	}

	.tooltip {
		opacity: 0;
		width: 0;
		height: 0;
		transition: opacity 300ms ease-out;
		transition-duration: 1s;
		position: absolute;
		top: 1em;
	}
}

.loadingOverlay {
	background-color: var(--color-neutral-dark);
	position: absolute;
	opacity: 0.4;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: all 250ms ease-out;
	z-index: 100;

	&.active {
		opacity: 1;
		z-index: 9999;
	}

	.roller {
		display: inline-block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 80px;
		height: 80px;

		div {
			animation: roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			transform-origin: 40px 40px;

			&::after {
				content: ' ';
				display: block;
				position: absolute;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: var(--color-main);
				margin: -4px 0 0 -4px;
			}

			&:nth-child(1) {
				animation-delay: -0.036s;

				&::after {
					top: 63px;
					left: 63px;
				}
			}

			&:nth-child(2) {
				animation-delay: -0.072s;

				&::after {
					top: 68px;
					left: 56px;
				}
			}

			&:nth-child(3) {
				animation-delay: -0.108s;

				&::after {
					top: 71px;
					left: 48px;
				}
			}

			&:nth-child(4) {
				animation-delay: -0.144s;

				&::after {
					top: 72px;
					left: 40px;
				}
			}

			&:nth-child(5) {
				animation-delay: -0.18s;

				&::after {
					top: 71px;
					left: 32px;
				}
			}

			&:nth-child(6) {
				animation-delay: -0.216s;

				&::after {
					top: 68px;
					left: 24px;
				}
			}

			&:nth-child(7) {
				animation-delay: -0.252s;

				&::after {
					top: 63px;
					left: 17px;
				}
			}

			&:nth-child(8) {
				animation-delay: -0.288s;

				&::after {
					top: 56px;
					left: 12px;
				}
			}
		}
	}

	@keyframes roller {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
