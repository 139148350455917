.toggle {
	font-family: Arial, Helvetica, sans-serif;
	width: 50px;
	background-color: var(--color-text-light);
	user-select: none;
	border-radius: var(--borderRadius);
	padding: 2px;
	height: 26px;
	position: relative;

	.dialog-button {
		font-size: 11px;
		line-height: 10px;
		font-weight: bold;
		cursor: pointer;
		background-color: #002b49;
		color: white;
		padding: 8px 12px;
		border-radius: var(--borderRadius);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 38px;
		min-width: unset;
		box-sizing: border-box;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
		position: absolute;
		top: 0;
		right: -4px;
		transition: all 0.15s ease-out;
	}

	.disabled {
		background-color: #707070;
		transform: translateX(-18px);
	}
}
