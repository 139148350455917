.postList {
	position: relative;
	line-height: 2;
	height: 100%;
	list-style: none;
	margin-bottom: $postLinks__height;
	transition: background-color 150ms ease;
	overflow: unset;

	&.draggingOver {
		background-color: var(--color-main-transparent);

		&:not(.unscheduledDrafts) {
			.post {
				&:not(.dragging) {
					transform: none !important;
				}
			}
		}

		.placeholder {
			opacity: 0;
			transition: all 100ms ease;
		}
	}

	.post {
		border: 2px solid transparent;
		border-radius: var(--borderRadius);
		padding-bottom: 0;
		color: var(--color-neutral);
		cursor: pointer;
		position: relative;
		transition: all 100ms ease-in-out;

		&:hover {
			margin-bottom: 30px !important;

			.postLinks {
				bottom: -30px;
				position: absolute;
				z-index: 1;
			}
		}

		&.loading {
			pointer-events: none;

			.postData {
				background: linear-gradient(
					110deg,
					rgba(236, 236, 236, 0.2) 8%,
					rgba(245, 245, 245, 0.8) 18%,
					rgba(236, 236, 236, 0.2) 33%
				);
				opacity: 0.6;
				border-radius: 5px;
				background-size: 200% 100%;
				animation: 1.5s shine linear infinite;
			}
		}

		&.notransition {
			-webkit-transition: none;
			-moz-transition: none;
			-o-transition: none;
			transition: none;
		}

		&.dragging {
			&.over {
				&__unscheduled,
				&__calendar {
					width: 100%;
					flex: 0 0 100%;
				}

				&__none {
					opacity: 0.5;
				}
			}
		}

		.postData {
			pointer-events: none;
			margin: 0;
			padding: 4px;
			border: 2px solid transparent;
			border-radius: var(--borderRadius);
			position: relative;
			z-index: 1;
			font-size: 90%;
			font-weight: 400;
			overflow: hidden;
			transition: all 100ms;

			&__title {
				display: flex;
				padding: 0 1px;
				width: 100%;
				margin: 0;
				pointer-events: none;
				justify-content: space-between;
				align-items: center;

				.icon {
					color: inherit;
					opacity: 0.75;
				}
			}
		}

		.postLinks {
			border-radius: var(--borderRadius);
			background-color: var(--color-main-transparent);
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			justify-content: center;
			min-height: $postLinks__height;
			padding: 0 2px;
			margin: 0;
			overflow: hidden;
			position: absolute;
			bottom: 0;
			right: 50%;
			transform: translateX(50%);
			width: 100%;
			z-index: -1;

			.postLink {
				flex: 1;
				border-radius: 50%;
				position: relative;

				.icon {
					text-decoration: none;
					padding: 25%;
					font-size: 20px;
					background: transparent;
					@include absoluteCenter;

					&:hover {
						color: var(--color-text-dark);
						background-color: var(--color-main-transparent);
					}

					&__trash {
						&:hover {
							color: var(--color-error);
						}
					}
				}
			}
		}

		&.currentPost {
			.postData {
				border: 2px solid var(--color-main-light);
			}
		}
	}

	.placeholder {
		border: 2px var(--color-text) dashed;
		border-radius: var(--borderRadius);
		height: 50px;

		ul & {
			display: block;
			position: relative;
			height: 60px;
		}
	}

	&.unscheduledDrafts {
		.post {
			margin: 0;

			&:hover {
				.postLinks {
					width: 100%;
				}
			}
		}
	}
}
